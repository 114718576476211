import {
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGOUT_BEGIN,
  PROFILE_UPDATE,
  USER_DATA,
} from '../actions/actionType'

const initialState = {
  isLoggedIn: null,
  salutation_id: null,
  id: 0,
  name: '',
  first_name: '',
  last_name: '',
  email: '',
  home_phone: '',
  cell_phone: '',
  office_phone: '',
  office_extension: '',
  job_title: null,
  address1: null,
  address2: null,
  city: null,
  postal_code: null,
  province_id: null,
  country_id: null,
  language_id: 1,
  warehouse_id: null,
  status: 0,
  department_id: null,
  employee: null,
  role: [],
  permissions: [],
  clients: [],
  errorMsg: '',
  email_verified_at: null,
}
export default function currentUserReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_BEGIN:
      return { ...initialState, isLoggedIn: false }
    case LOGOUT_BEGIN:
      return { ...initialState, isLoggedIn: false }
    case LOGIN_ERROR:
      return { ...action.payload, isLoggedIn: false }
    case USER_DATA:
      return { ...action.payload, isLoggedIn: true }
    case PROFILE_UPDATE:
      return { ...action.payload, isLoggedIn: true }
    default:
      return state
  }
}
