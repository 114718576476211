import { THEME } from 'src/actions/actionType'
import { LIGHT } from 'src/constants/Constants'

const initialState = {
  theme: localStorage.getItem(THEME) || LIGHT,
}
export default function ThemeReducer(state = initialState, { type, ...rest }) {
  switch (type) {
    case THEME:
      localStorage.setItem(THEME, rest.theme)
      return { ...state, ...rest }
    default:
      return state
  }
}
