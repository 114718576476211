import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/errors', { params: param })
}

const get = (id) => {
  return http.get(`/errors/${id}`)
}
const create = (data) => {
  return http.post('/errors', data)
}

const update = (id, data) => {
  return http.put(`/errors/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/errors/${id}`)
}

const ErrorsDataService = {
  getAll,
  get,
  create,
  update,
  remove,
}
export default ErrorsDataService
