import {
  BILLING_CATEGORIES_DATA,
  BILLING_LINE_CHARGES_DATA,
  BILLING_UOM_DATA,
} from '../actions/actionType'

const initialState = {
  categories: [],
  lineCharges: [],
  uomTypes: [],
}

export default function JobsReducer(state = initialState, action) {
  switch (action.type) {
    case BILLING_CATEGORIES_DATA:
      return { ...state, categories: action.payload }
    case BILLING_LINE_CHARGES_DATA:
      return { ...state, lineCharges: action.payload }
    case BILLING_UOM_DATA:
      return { ...state, uomTypes: action.payload }
    default:
      return state
  }
}
