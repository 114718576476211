import { ITEM_TYPES_DATA } from '../actions/actionType'

const initialState = {
  itemTypes: [],
}
export default function ItemTypesReducer(state = initialState, action) {
  switch (action.type) {
    case ITEM_TYPES_DATA:
      return { ...state, itemTypes: action.payload }
    default:
      return state
  }
}
