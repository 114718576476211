import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/countries', { params: param })
}

const get = (id) => {
  return http.get(`/countries/${id}`)
}

const create = (data) => {
  return http.post('/countries', data)
}

const update = (id, data) => {
  return http.put(`/countries/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/countries/${id}`)
}

const removeAll = () => {
  return http.delete(`/countries`)
}

const findByTitle = (title) => {
  return http.get(`/countries?title=${title}`)
}

const emailExportCountries = () => {
  return http.get(`/export/countries`)
}

const CountryDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  emailExportCountries,
}

export default CountryDataService
