import { REPLENISH_STATUS_DATA } from '../actions/actionType'

const initialState = {
  replenishStatus: [],
}

export default function ReplenishStatusReducer(state = initialState, action) {
  switch (action.type) {
    case REPLENISH_STATUS_DATA:
      return { ...state, replenishStatus: action.payload }
    default:
      return state
  }
}
