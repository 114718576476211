import { DELIVERY_TYPE_DATA } from '../actions/actionType'

const initialState = {
  deliveryTypes: [],
}
export default function DeliveryTypeReducer(state = initialState, action) {
  switch (action.type) {
    case DELIVERY_TYPE_DATA:
      return { ...state, deliveryTypes: action.payload }
    default:
      return state
  }
}
