import { WAREHOUSE_DATA } from '../actions/actionType'
const initialState = {
  warehouses: [],
}
export default function WarehouseReducer(state = initialState, action) {
  switch (action.type) {
    case WAREHOUSE_DATA:
      return { ...state, warehouses: action.payload }
    default:
      return state
  }
}
