import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/item-linguistics', { params: param })
}

const ItemLinguisticsDataService = {
  getAll,
}

export default ItemLinguisticsDataService
