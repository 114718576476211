import { RECEIVING_PO_STATUS_DATA } from '../actions/actionType'

const initialState = {
  receivingPOStatusList: [],
}
export default function ReceivingPOStatusesReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVING_PO_STATUS_DATA:
      return { ...state, receivingPOStatusList: action.payload }
    default:
      return state
  }
}
