import http from '../utils/http-common'

const getAll = () => {
  return http.get('/item-reasons')
}

const getByParams = (type, key) => {
  return http.get(`/item-reasons?type=${type}&key=${key}`)
}

const ItemReasonsDataService = {
  getAll,
  getByParams,
}
export default ItemReasonsDataService
