import { ZONE_CATEGORIES_DATA } from '../actions/actionType'

const initialState = {
  zoneCategories: [],
}

export default function ZoneCategoriesReducer(state = initialState, action) {
  switch (action.type) {
    case ZONE_CATEGORIES_DATA:
      return { ...state, zoneCategories: action.payload }
    default:
      return state
  }
}
