import { SALUTATION_DATA } from '../actions/actionType'

const initialState = {
  salutations: [],
}
export default function SalutationReducer(state = initialState, action) {
  switch (action.type) {
    case SALUTATION_DATA:
      return { ...state, salutations: action.payload }
    default:
      return state
  }
}
