import { LOCATION_CATEGORY_DATA } from '../actions/actionType'

const initialState = {
  locationCategories: [],
}
export default function LocationCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CATEGORY_DATA:
      return { ...state, locationCategories: action.payload }
    default:
      return state
  }
}
