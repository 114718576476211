import { ITEM_LINGUISTICS_DATA } from '../actions/actionType'

const initialState = {
  itemLinguistics: [],
}
export default function ItemLinguisticsReducer(state = initialState, action) {
  switch (action.type) {
    case ITEM_LINGUISTICS_DATA:
      return { ...state, itemLinguistics: action.payload }
    default:
      return state
  }
}
