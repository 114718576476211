import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/billing-uoms', { params: param })
}

const get = (id) => {
  return http.get(`/billing-uoms/${id}`)
}

const create = (data) => {
  return http.post('/billing-uoms', data)
}

const update = (id, data) => {
  return http.put(`/billing-uoms/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/billing-uoms/${id}`)
}

const removeAll = () => {
  return http.delete(`/billing-uoms`)
}

const findByTitle = (title) => {
  return http.get(`/billing-uoms?title=${title}`)
}

const BillingUOMDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
}

export default BillingUOMDataService
