import http from '../utils/http-common'
const getAll = (param) => {
  return http.get('/provinces', { params: param })
}
const get = (id) => {
  return http.get(`/provinces/${id}`)
}
const create = (data) => {
  return http.post('/provinces', data)
}
const update = (id, data) => {
  return http.put(`/provinces/${id}`, data)
}
const remove = (id) => {
  return http.delete(`/provinces/${id}`)
}
const removeAll = () => {
  return http.delete(`/provinces`)
}
const findByTitle = (title) => {
  return http.get(`/provinces?title=${title}`)
}
const ProvinceDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
}
export default ProvinceDataService
