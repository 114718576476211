import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/item-types', { params: param })
}

const ItemTypesDataService = {
  getAll,
}
export default ItemTypesDataService
