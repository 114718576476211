import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/quantity-types', { params: param })
}

const QuantityTypesDataService = {
  getAll,
}

export default QuantityTypesDataService
