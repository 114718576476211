import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/billing-line-charges', { params: param })
}

const get = (id) => {
  return http.get(`/billing-line-charges/${id}`)
}

const create = (data) => {
  return http.post('/billing-line-charges', data)
}

const update = (id, data) => {
  return http.put(`/billing-line-charges/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/billing-line-charges/${id}`)
}

const removeAll = () => {
  return http.delete(`/billing-line-charges`)
}

const findByTitle = (title) => {
  return http.get(`/billing-line-charges?title=${title}`)
}

const BillingLineChargesDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
}

export default BillingLineChargesDataService
