import { CARRIERS_DATA, SERVICES_DATA } from '../actions/actionType'

const initialState = {
  carriers: [],
  services: [],
}
export default function CarriersReducer(state = initialState, action) {
  switch (action.type) {
    case CARRIERS_DATA:
      return { ...state, carriers: action.payload }
    case SERVICES_DATA:
      return { ...state, services: action.payload }
    default:
      return state
  }
}
