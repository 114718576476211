import { encodeValue } from 'src/utils/helpers'
import http from '../utils/http-common'

const getAll = (param, cancelToken) => {
  return http.get('/orders', {
    params: param,
    cancelToken: cancelToken?.token,
  })
}

const getStatus = () => {
  return http.get(`/order-statuses`)
}

const getOrderItemStatus = () => {
  return http.get(`/order-item-statuses`)
}

const create = (data) => {
  return http.post('/orders', data)
}

const update = (id, data) => {
  return http.put(`/orders/${id}`, data)
}

const get = (id) => {
  return http.get(`/orders/${id}`)
}

const changeStatus = (orders, order_status_id) => {
  return http.post(`/orders/change-status`, { orders, order_status_id })
}

const cancelOrders = (data) => {
  return http.post(`/orders/cancel`, data)
}

const addComment = (data) => {
  return http.post(`/order-comments`, data)
}

const deleteComment = (id) => {
  return http.delete(`/order-comments/${id}`)
}

const batchOrders = (data) => {
  return http.post(`/batches`, data)
}

const unProcessOrders = (data) => {
  return http.post(`/orders/unprocess`, data)
}

const unBatchOrders = (data) => {
  return http.post(`/unbatch`, data)
}

const getCSVTemplate = () => {
  return http.get(`/import/order-structure`)
}

const importCSV = (data) => {
  return http.post(`/import/orders`, data)
}

const getOrderPackages = (param) => {
  return http.get(`/order-package`, { params: param })
}

const getOrderPackageStatus = () => {
  return http.get(`/order-package-statuses`)
}

const exportOrders = (param) => {
  return http.get(`/export/orders`, { params: param })
}

const resendToProXpert = (data) => {
  return http.post(`/orders/resend-to-proxpert`, data)
}

const changeOrderStatus = (data) => {
  return http.post(`/orders/change/order-status`, data)
}

const changeOrderState = (data) => {
  return http.post(`/orders/change/order-state`, data)
}

const getOrderByOrderNumber = (orderNumber, clientId) => {
  return http.get(
    `/orders?list=first&order_number=${encodeValue(orderNumber)}&client_id=${clientId}`,
  )
}

const deleteOrderItems = (id) => {
  return http.delete(`/order-items/${id}`)
}

const getOrderByFields = () => {
  return http.get(`/orders/order-by/fields`)
}

const deleteOrder = (data) => {
  return http.post(`/orders/delete`, data)
}

const returnOrderDetails = (param) => {
  return http.get(`return-orders`, { params: param })
}

const createShipment = (data) => {
  return http.post(`/orders/shipment/create`, data)
}

const createIntegrationOrder = (data) => {
  return http.post(`/orders/create/integration`, data)
}

const getProxOrderSerialNumbers = (param) => {
  return http.get(`/orders/prox/serial-numbers`, { params: param })
}

const createProxOrderSerialNumber = (data) => {
  return http.post(`/orders/prox/serial-numbers`, data)
}

const updateProxSerialNumber = (id, data) => {
  return http.put(`/orders/prox/serial-numbers/${id}`, data)
}

const deleteProxSerialNumber = (id) => {
  return http.delete(`/return-item/${id}`)
}

const getQuickBatchInfo = (data) => {
  return http.post(`/batches/quick-batches/info`, data)
}

const createQuickBatch = (data) => {
  return http.post(`/batches/quick-batches/create`, data)
}

const putOrdersOnHold = (data) => {
  return http.post(`/orders/state/on-hold`, data)
}

const releaseOrdersFromHold = (data) => {
  return http.post(`/orders/state/release`, data)
}

const orderProcessingDetails = (id) => {
  return http.get(`/orders/${id}/processing-details/`)
}

const retrieveProxpertOrders = (data) => {
  return http.post(`/orders/retrieve/prox/order`, data)
}

const uploadLabel = (data) => {
  return http.post(`/order-documents`, data)
}

const deleteLabel = (id) => {
  return http.delete(`/order-documents/${id}`)
}

const getDocuments = (data) => {
  return http.post(`/orders/packing/download/documents`, data)
}

const OrdersDataService = {
  getAll,
  getStatus,
  getOrderItemStatus,
  create,
  update,
  get,
  changeStatus,
  changeOrderState,
  addComment,
  deleteComment,
  batchOrders,
  unProcessOrders,
  exportOrders,
  getCSVTemplate,
  importCSV,
  getOrderPackages,
  getOrderPackageStatus,
  cancelOrders,
  resendToProXpert,
  changeOrderStatus,
  getOrderByOrderNumber,
  deleteOrderItems,
  getOrderByFields,
  deleteOrder,
  returnOrderDetails,
  createShipment,
  createIntegrationOrder,
  getProxOrderSerialNumbers,
  updateProxSerialNumber,
  deleteProxSerialNumber,
  createProxOrderSerialNumber,
  getQuickBatchInfo,
  createQuickBatch,
  putOrdersOnHold,
  releaseOrdersFromHold,
  orderProcessingDetails,
  unBatchOrders,
  retrieveProxpertOrders,
  uploadLabel,
  deleteLabel,
  getDocuments,
}
export default OrdersDataService
