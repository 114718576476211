import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/languages', { params: param })
}
const get = (id) => {
  return http.get(`/languages/${id}`)
}
const create = (data) => {
  return http.post('/languages', data)
}
const update = (id, data) => {
  return http.put(`/languages/${id}`, data)
}
const remove = (id) => {
  return http.delete(`/languages/${id}`)
}
const removeAll = () => {
  return http.delete(`/languages`)
}
const findByTitle = (title) => {
  return http.get(`/languages?title=${title}`)
}
const LanguageDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
}

export default LanguageDataService
