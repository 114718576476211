import { PROCESSING_TYPES_DATA } from '../actions/actionType'
const initialState = {
  processingTypes: [],
}
export default function ProcessingTypesReducer(state = initialState, action) {
  switch (action.type) {
    case PROCESSING_TYPES_DATA:
      return { ...state, processingTypes: action.payload }
    default:
      return state
  }
}
