import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/order-types', { params: param })
}

const OrderTypesDataService = {
  getAll,
}

export default OrderTypesDataService
