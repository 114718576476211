import { HOLIDAY_DATA } from '../actions/actionType'

const initialState = {
  holidays: [],
}
export default function HolidayReducer(state = initialState, action) {
  switch (action.type) {
    case HOLIDAY_DATA:
      return { ...state, holidays: action.payload }
    default:
      return state
  }
}
