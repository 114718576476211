import { DEPARTMENTS_DATA } from '../actions/actionType'

const initialState = {
  departments: [],
}
export default function DepartmentsReducer(state = initialState, action) {
  switch (action.type) {
    case DEPARTMENTS_DATA:
      return { ...state, departments: action.payload }
    default:
      return state
  }
}
