import { RECEIVING_ITEM_CONDITION_DATA } from '../actions/actionType'

const initialState = {
  receivingItemConditionsList: [],
}
export default function ReceivingItemConditionsReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVING_ITEM_CONDITION_DATA:
      return { ...state, receivingItemConditionsList: action.payload }
    default:
      return state
  }
}
