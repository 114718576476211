import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/uom-types', { params: param })
}

const UomTypesDataService = {
  getAll,
}
export default UomTypesDataService
