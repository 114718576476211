import { SELECTED_INVENTORY } from '../actions/actionType'

const initialState = {
  selected: [],
}
export default function InventoryReducer(state = initialState, action) {
  switch (action.type) {
    case SELECTED_INVENTORY:
      return { ...state, selected: action.payload }
    default:
      return state
  }
}
