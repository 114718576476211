import { PO_ITEM_STATUSES_DATA } from '../actions/actionType'

const initialState = {
  purchaseOrderItemStatusesList: [],
}
export default function PurchaseOrderItemReducer(state = initialState, action) {
  switch (action.type) {
    case PO_ITEM_STATUSES_DATA:
      return { ...state, purchaseOrderItemStatusesList: action.payload }
    default:
      return state
  }
}
