import { CLIENTS_DATA } from '../actions/actionType'
const initialState = {
  clients: [],
}
export default function ClientsReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENTS_DATA:
      return { ...state, clients: action.payload }
    default:
      return state
  }
}
