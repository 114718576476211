import { ITEM_REASON_DATA } from '../actions/actionType'

const initialState = {
  itemReasons: [],
}
export default function ItemReasonsReducer(state = initialState, action) {
  switch (action.type) {
    case ITEM_REASON_DATA:
      return { ...state, itemReasons: action.payload }
    default:
      return state
  }
}
