import { Cookies } from 'react-cookie'
const cookies = new Cookies()
const AUTH_USER = 'auth-user'
const ACCESS_TOKEN = 'access_token'

const cookiesUtils = (function () {
  let setCookie = (key, value, expires) => {
    cookies.set(key, value, { path: '/', expires })
  }

  let getCookie = (key) => {
    return cookies.get(key)
  }

  let removeCookie = (key) => {
    return cookies.remove(key)
  }

  return {
    setAuthorisedUser: function (userObj, expires) {
      setCookie(AUTH_USER, userObj, expires)
    },

    getAuthorisedUser: function () {
      return getCookie(AUTH_USER)
    },

    setAccessToken: function (userObj, expires) {
      setCookie(ACCESS_TOKEN, userObj, expires)
    },

    getAccessToken: function () {
      return getCookie(ACCESS_TOKEN)
    },
    logout: function () {
      removeCookie(AUTH_USER)
      removeCookie(ACCESS_TOKEN)
    },
  }
})()
export default cookiesUtils
