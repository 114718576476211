import { RECEIVING_STATUS_DATA } from '../actions/actionType'

const initialState = {
  statusList: [],
}
export default function ReceivingStatusReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVING_STATUS_DATA:
      return { ...state, statusList: action.payload }
    default:
      return state
  }
}
