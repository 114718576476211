import { SUPPLY_TYPES_DATA } from '../actions/actionType'

const initialState = {
  supplyTypes: [],
}
export default function SupplyTypesReducer(state = initialState, action) {
  switch (action.type) {
    case SUPPLY_TYPES_DATA:
      return { ...state, supplyTypes: action.payload }
    default:
      return state
  }
}
