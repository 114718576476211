import { ITEM_CONDITIONS_DATA } from '../actions/actionType'

const initialState = {
  itemConditions: [],
}
export default function ItemConditionsReducer(state = initialState, action) {
  switch (action.type) {
    case ITEM_CONDITIONS_DATA:
      return { ...state, itemConditions: action.payload }
    default:
      return state
  }
}
