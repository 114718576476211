import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/settings', { params: param })
}

const get = (id) => {
  return http.get(`/settings/${id}`)
}

const create = (data) => {
  return http.post('/settings', data)
}

const update = (id, data) => {
  return http.put(`/settings/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/settings/${id}`)
}

const removeAll = () => {
  return http.delete(`/settings`)
}

const findByTitle = (title) => {
  return http.get(`/settings?title=${title}`)
}

const SettingDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
}
export default SettingDataService
