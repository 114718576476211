import { PICKING_SKIPS_DATA, PICKING_SKIP_REASONS_DATA } from '../actions/actionType'

const initialState = {
  pickingSkips: [],
  pickingSkipReason: [],
}
export default function PickingReducer(state = initialState, action) {
  switch (action.type) {
    case PICKING_SKIPS_DATA:
      return { ...state, pickingSkips: action.payload }
    case PICKING_SKIP_REASONS_DATA:
      return { ...state, pickingSkipReason: action.payload }
    default:
      return state
  }
}
