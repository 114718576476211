import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/picking-methods', { params: param })
}
const get = (id) => {
  return http.get(`/picking-methods/${id}`)
}

const getPickingJob = (param) => {
  return http.get('/order-item-picking-job', { params: param })
}

const beginPickingJob = (data) => {
  return http.post('/begin-order-picking-job', data)
}

const transferPikingItem = (data) => {
  return http.post('/transfer-order-item-picking-job', data)
}

const transferBatchPikingItem = (data) => {
  return http.post('/transfer-order-item-to-tote-job', data)
}

const getPickingList = (jobId, data) => {
  return http.post(`/orders/picking/${jobId}`, data)
}

const getPickingSkips = (param) => {
  return http.get('/picking-skips', { params: param })
}

const getSkipReasons = (param) => {
  return http.get('/skip-reasons', { params: param })
}

const getPickableTypes = (param) => {
  return http.get('/pickable-types', { params: param })
}

const transferItemsToTote = (ticketId, data) => {
  return http.post(`orders/picking/transfer/to-tote/${ticketId}`, data)
}

const skipPickingItem = (data) => {
  return http.post(`order-item-pickings/skip`, data)
}

const unSkipPickingItem = (data) => {
  return http.post(`order-item-pickings/skip/remove`, data)
}

const getPickingJobDetails = (id, param) => {
  return http.get(`/orders/picking/details/${id}`, { params: param })
}

const PickingDataService = {
  getAll,
  get,
  getPickingJob,
  beginPickingJob,
  transferPikingItem,
  transferBatchPikingItem,
  getPickingList,
  getPickingSkips,
  getSkipReasons,
  transferItemsToTote,
  skipPickingItem,
  unSkipPickingItem,
  getPickingJobDetails,
  getPickableTypes,
}
export default PickingDataService
