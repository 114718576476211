import { ORDER_FIELD_LIST } from '../actions/actionType'

const initialState = {
  fields: [],
}
export default function OrderReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_FIELD_LIST:
      return { ...state, fields: action.payload }
    default:
      return state
  }
}
