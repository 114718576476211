import { SETTING_DATA } from '../actions/actionType'
const initialState = {
  settings: [],
}
export default function SettingsReducer(state = initialState, action) {
  switch (action.type) {
    case SETTING_DATA:
      return { ...state, settings: action.payload }
    default:
      return state
  }
}
