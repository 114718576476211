import { SUPPLIERS_DATA } from '../actions/actionType'

const initialState = {
  suppliers: [],
}
export default function SuppliersReducer(state = initialState, action) {
  switch (action.type) {
    case SUPPLIERS_DATA:
      return { ...state, suppliers: action.payload }
    default:
      return state
  }
}
