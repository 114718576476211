import { MODIFICATION_TYPE_DATA } from '../actions/actionType'

const initialState = {
  modificationTypes: [],
}
export default function ModificationTypesReducer(state = initialState, action) {
  switch (action.type) {
    case MODIFICATION_TYPE_DATA:
      return { ...state, modificationTypes: action.payload }
    default:
      return state
  }
}
