import http from '../utils/http-common'
const getAll = (param) => {
  return http.get('/suppliers', { params: param })
}
const get = (id) => {
  return http.get(`/suppliers/${id}`)
}
const getSupplierByClient = (id) => {
  return http.get(`/suppliers?client_id=${id}&list=auto-complete`)
}
const create = (data) => {
  return http.post('/suppliers', data)
}
const update = (id, data) => {
  return http.put(`/suppliers/${id}`, data)
}
const remove = (id) => {
  return http.delete(`/suppliers/${id}`)
}
const removeAll = () => {
  return http.delete(`/suppliers`)
}

const SupplierDataService = {
  getAll,
  get,
  getSupplierByClient,
  create,
  update,
  remove,
  removeAll,
}
export default SupplierDataService
