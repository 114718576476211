import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/holidays', { params: param })
}

const get = (id) => {
  return http.get(`/holidays/${id}`)
}
const create = (data) => {
  return http.post('/holidays', data)
}

const update = (id, data) => {
  return http.put(`/holidays/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/holidays/${id}`)
}

const getCSVTemplate = () => {
  return http.get(`/import/holiday-structure`)
}

const importCSV = (data) => {
  return http.post(`/import/holidays`, data)
}

const HolidaysDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  getCSVTemplate,
  importCSV,
}
export default HolidaysDataService
