import {
  ORDER_ITEM_STATUS_DATA,
  ORDER_PACKAGE_STATUS_DATA,
  ORDER_STATUS_DATA,
} from '../actions/actionType'

const initialState = {
  statusList: [],
  orderItemStatusList: [],
  orderPackageStatusList: [],
}
export default function OrderStatusReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_STATUS_DATA:
      return { ...state, statusList: action.payload }
    case ORDER_ITEM_STATUS_DATA:
      return { ...state, orderItemStatusList: action.payload }
    case ORDER_PACKAGE_STATUS_DATA:
      return { ...state, orderPackageStatusList: action.payload }
    default:
      return state
  }
}
