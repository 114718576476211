import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/delivery-types', { params: param })
}

const DeliveryTypeDataService = {
  getAll,
}
export default DeliveryTypeDataService
