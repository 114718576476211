import { RECEIVING_ITEM_STATUS_DATA } from '../actions/actionType'

const initialState = {
  receivingItemStatusList: [],
}
export default function ReceivingItemStatusesReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVING_ITEM_STATUS_DATA:
      return { ...state, receivingItemStatusList: action.payload }
    default:
      return state
  }
}
