import { WEIGHT_CLASS_DATA } from '../actions/actionType'

const initialState = {
  weightClasses: [],
}
export default function WeightClassReducer(state = initialState, action) {
  switch (action.type) {
    case WEIGHT_CLASS_DATA:
      return { ...state, weightClasses: action.payload }
    default:
      return state
  }
}
