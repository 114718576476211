import http from '../utils/http-common'

const getProcessingTypes = (param) => {
  return http.get('/processing-types', { params: param })
}

const ProcessingTypeDataService = {
  getProcessingTypes,
}

export default ProcessingTypeDataService
