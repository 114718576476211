import http from '../utils/http-common'

const getReturnReason = () => {
  return http.get('/return-reasons')
}
const getReturnStatuses = () => {
  return http.get('/return-statuses')
}
const getReturnActions = () => {
  return http.get('/return-actions')
}
const getReturnItemReasons = () => {
  return http.get('/return-item-reasons')
}
const getReturnItemConditions = () => {
  return http.get('/return-item-conditions')
}
const getReturnItemActions = () => {
  return http.get('/return-item-actions')
}

const getReturnItemStatuses = () => {
  return http.get('/return-item-statuses')
}

const ReturnStatusService = {
  getReturnReason,
  getReturnStatuses,
  getReturnActions,
  getReturnItemReasons,
  getReturnItemConditions,
  getReturnItemActions,
  getReturnItemStatuses,
}
export default ReturnStatusService
