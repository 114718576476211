import { ORDER_TYPES_DATA } from '../actions/actionType'

const initialState = {
  orderTypes: [],
}
export default function OrderTypesReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_TYPES_DATA:
      return { ...state, orderTypes: action.payload }
    default:
      return state
  }
}
