import { TAX_DATA } from '../actions/actionType'

const initialState = {
  taxes: [],
}
export default function TaxesReducer(state = initialState, action) {
  switch (action.type) {
    case TAX_DATA:
      return { ...state, taxes: action.payload }
    default:
      return state
  }
}
