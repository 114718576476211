import { Ability, AbilityBuilder } from '@casl/ability'
import { store } from '../store'

const ability = new Ability()

const CAN = (action, subject) => {
  return ability.can(action, subject)
}

let currentAuth = {}
store.subscribe(() => {
  const prevAuth = currentAuth
  currentAuth = store.getState().auth
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth))
  }
})

const defineRulesFor = (auth) => {
  const permissions = auth.permissions
  const { can, rules } = new AbilityBuilder()

  if (permissions) {
    permissions.forEach((permission) => can(permission.name))
  }
  return rules
}
export default CAN
