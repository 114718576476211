import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/transactions', { params: param })
}

const getTransactionDetails = (id) => {
  return http.get(`/transactions/detail/${id}`)
}

const getTransactionTypes = (param) => {
  return http.get('/transaction-types', { params: param })
}

const exportTransactions = (param) => {
  return http.get(`/export/transaction-details`, { params: param })
}

const TransactionsDataService = {
  getAll,
  getTransactionTypes,
  getTransactionDetails,
  exportTransactions,
}
export default TransactionsDataService
