import { CURRENCY_DATA } from '../actions/actionType'

const initialState = {
  currencies: [],
}
export default function CurrenciesReducer(state = initialState, action) {
  switch (action.type) {
    case CURRENCY_DATA:
      return { ...state, currencies: action.payload }
    default:
      return state
  }
}
