import http from '../utils/http-common'
const getAll = (param) => {
  return http.get('/departments', { params: param })
}
const get = (id) => {
  return http.get(`/departments/${id}`)
}
const create = (data) => {
  return http.post('/departments', data)
}
const update = (id, data) => {
  return http.put(`/departments/${id}`, data)
}
const remove = (id) => {
  return http.delete(`/departments/${id}`)
}
const updateLedger = (id, data) => {
  return http.put(`/general-ledgers/${id}`, data)
}
const DepartmentDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  updateLedger,
}
export default DepartmentDataService
