import {
  RETURN_ACTIONS_DATA,
  RETURN_ITEM_ACTIONS_DATA,
  RETURN_ITEM_CONDITIONS_DATA,
  RETURN_ITEM_REASONS_DATA,
  RETURN_ITEM_STATUSES_DATA,
  RETURN_REASON_DATA,
  RETURN_STATUSES_DATA,
} from '../actions/actionType'

const initialState = {
  returnReasonList: [],
  returnStatusesList: [],
  returnActionsList: [],
  returnItemReasonsList: [],
  returnItemConditionsList: [],
  returnItemActionsList: [],
  returnItemStatusesList: [],
}
export default function ReturnStatusReducer(state = initialState, action) {
  switch (action.type) {
    case RETURN_REASON_DATA:
      return { ...state, returnReasonList: action.payload }
    case RETURN_STATUSES_DATA:
      return { ...state, returnStatusesList: action.payload }
    case RETURN_ACTIONS_DATA:
      return { ...state, returnActionsList: action.payload }
    case RETURN_ITEM_REASONS_DATA:
      return { ...state, returnItemReasonsList: action.payload }
    case RETURN_ITEM_CONDITIONS_DATA:
      return { ...state, returnItemConditionsList: action.payload }
    case RETURN_ITEM_ACTIONS_DATA:
      return { ...state, returnItemActionsList: action.payload }
    case RETURN_ITEM_STATUSES_DATA:
      return { ...state, returnItemStatusesList: action.payload }
    default:
      return state
  }
}
