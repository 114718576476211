import { COUNTRIES_DATA } from '../actions/actionType'

const initialState = {
  countries: [],
}
export default function CountriesReducer(state = initialState, action) {
  switch (action.type) {
    case COUNTRIES_DATA:
      return { ...state, countries: action.payload }
    default:
      return state
  }
}
