import { ITEM_STATUS_DATA } from '../actions/actionType'

const initialState = {
  itemStatus: [],
}
export default function ItemStatusReducer(state = initialState, action) {
  switch (action.type) {
    case ITEM_STATUS_DATA:
      return { ...state, itemStatus: action.payload }
    default:
      return state
  }
}
