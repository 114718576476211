import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/item-statuses', { params: param })
}

const ItemStatusesDataService = {
  getAll,
}
export default ItemStatusesDataService
