import { APPOINTMENT_TASKS_DATA } from '../actions/actionType'

const initialState = {
  appointmentTasks: [],
}
export default function AppointmentTasksReducer(state = initialState, action) {
  switch (action.type) {
    case APPOINTMENT_TASKS_DATA:
      return { ...state, appointmentTasks: action.payload }
    default:
      return state
  }
}
