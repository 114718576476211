import { combineReducers } from 'redux'
import { LOGOUT_BEGIN } from 'src/actions/actionType'
import { ACCESS_TOKEN_NAME, PERSIST_ROOT } from 'src/constants/Constants'
import AppointmentStatusReducer from './AppointmentStatusReducer'
import AppointmentStatusesReducer from './AppointmentStatusesReducer'
import AppointmentTasksReducer from './AppointmentTasksReducer'
import BillingReducer from './BillingReducer'
import CarriersReducer from './CarriersReducer'
import ClientsReducer from './ClientsReducer'
import CountriesReducer from './CountriesReducer'
import CurrenciesReducer from './CurrencyReducer'
import CurrentUserReducer from './CurrentUserReducer'
import DeliveryStatusReducer from './DeliveryStatusReducer'
import DeliveryTypeReducer from './DeliveryTypeReducer'
import DepartmentsReducer from './DepartmentsReducer'
import DoorTypeReducer from './DoorTypeReducer'
import ErrorsReducer from './ErrorsReducer'
import HolidayReducer from './HolidayReducer'
import InventoryReducer from './InventoryReducer'
import InvoiceStatusReducer from './InvoiceStatusReducer'
import ItemConditionsReducer from './ItemConditionsReducer'
import ItemLinguisticsReducer from './ItemLinguisticsReducer'
import ItemReasonsReducer from './ItemReasonsReducer'
import ItemStatusReducer from './ItemStatusReducer'
import ItemTypesReducer from './ItemTypesReducer'
import JobsReducer from './JobsReducer'
import LanguageReducer from './LanguageReducer'
import LengthClassReducer from './LengthClassReducer'
import LocationCategoryReducer from './LocationCategoryReducer'
import ModificationTypesReducer from './ModificationTypesReducer'
import OrderReducer from './OrderReducer'
import OrderStatusReducer from './OrderStatusReducer'
import OrderTypesReducer from './OrderTypesReducer'
import PickingMethodsReducer from './PickingMethodsReducer'
import PickingReducer from './PickingReducer'
import ProcessingTypesReducer from './ProcessingTypesReducer'
import ProvincesReducer from './ProvinceReducer'
import PurchaseOrderItemReducer from './PurchaseOrderItemReducer'
import PurchaseOrderReducer from './PurchaseOrderReducer'
import QuantityTypesReducer from './QuantityTypesReducer'
import ReceivingItemConditionsReducer from './ReceivingItemConditionsReducer'
import ReceivingItemReasonsReducer from './ReceivingItemReasonsReducer'
import ReceivingItemStatusesReducer from './ReceivingItemStatusesReducer'
import ReceivingPOReasonsReducer from './ReceivingPOReasonsReducer'
import ReceivingPOStatusesReducer from './ReceivingPOStatusesReducer'
import ReceivingStatusReducer from './ReceivingStatusReducer'
import ReturnStatusReducer from './ReturnStatusReducer'
import SalutationReducer from './SalutationReducer'
import SavedDataReducer from './SavedDataReducer'
import SettingsReducer from './SettingsReducer'
import ShippingMethodsReducer from './ShippingMethodsReducer'
import SidebarReducer from './SidebarSlice'
import SuppliersReducer from './SuppliersReducer'
import SupplyTypesReducer from './SupplyTypesReducer'
import TaxesReducer from './TaxesReducer'
import ThemeReducer from './ThemeSlice'
import TransactionTypesReducer from './TransactionTypesReducer'
import UomReducer from './UomReducer'
import WarehouseReducer from './WarehouseReducer'
import WeightClassReducer from './WeightClassReducer'
import ZoneCategoriesReducer from './ZoneCategoriesReducer'
import ReplenishStatusReducer from './ReplenishStatusReducer'

const appReducer = combineReducers({
  auth: CurrentUserReducer,
  sidebar: SidebarReducer,
  settings: SettingsReducer,
  languages: LanguageReducer,
  themes: ThemeReducer,
  countries: CountriesReducer,
  provinces: ProvincesReducer,
  suppliers: SuppliersReducer,
  carriers: CarriersReducer,
  orderTypes: OrderTypesReducer,
  departments: DepartmentsReducer,
  appointmentTasks: AppointmentTasksReducer,
  appointmentStatus: AppointmentStatusReducer,
  currencies: CurrenciesReducer,
  inventory: InventoryReducer,
  itemReasons: ItemReasonsReducer,
  modificationTypes: ModificationTypesReducer,
  warehouses: WarehouseReducer,
  purchaseOrders: PurchaseOrderReducer,
  purchaseOrderItem: PurchaseOrderItemReducer,
  shippingMethods: ShippingMethodsReducer,
  orderStatus: OrderStatusReducer,
  receivingStatus: ReceivingStatusReducer,
  returnStatus: ReturnStatusReducer,
  deliveryStatus: DeliveryStatusReducer,
  salutations: SalutationReducer,
  uom: UomReducer,
  locationCategories: LocationCategoryReducer,
  lengthClasses: LengthClassReducer,
  weightClasses: WeightClassReducer,
  supplyTypes: SupplyTypesReducer,
  itemTypes: ItemTypesReducer,
  itemStatus: ItemStatusReducer,
  jobs: JobsReducer,
  billing: BillingReducer,
  holidays: HolidayReducer,
  doorTypes: DoorTypeReducer,
  taxes: TaxesReducer,
  deliveryTypes: DeliveryTypeReducer,
  appointmentStatuses: AppointmentStatusesReducer,
  receivingItemReasons: ReceivingItemReasonsReducer,
  receivingItemStatuses: ReceivingItemStatusesReducer,
  receivingItemConditions: ReceivingItemConditionsReducer,
  receivingPOReasons: ReceivingPOReasonsReducer,
  receivingPOStatuses: ReceivingPOStatusesReducer,
  quantityTypes: QuantityTypesReducer,
  itemConditions: ItemConditionsReducer,
  itemLinguistics: ItemLinguisticsReducer,
  pickingMethods: PickingMethodsReducer,
  picking: PickingReducer,
  errors: ErrorsReducer,
  orders: OrderReducer,
  clients: ClientsReducer,
  transactionTypes: TransactionTypesReducer,
  savedData: SavedDataReducer,
  invoiceStatus: InvoiceStatusReducer,
  processingTypes: ProcessingTypesReducer,
  zoneCategories: ZoneCategoriesReducer,
  replenishStatus: ReplenishStatusReducer,
})

export default function rootReducer(state, action) {
  if (action.type === LOGOUT_BEGIN) {
    localStorage.removeItem(PERSIST_ROOT)
    localStorage.removeItem(ACCESS_TOKEN_NAME)
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
