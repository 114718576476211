import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/zones', { params: param })
}

const get = (id) => {
  return http.get(`/zones/${id}`)
}

const createZone = (data) => {
  return http.post('/zones', data)
}

const updateZone = (id, data) => {
  return http.put(`/zones/${id}`, data)
}

const getZoneCategories = (param) => {
  return http.get('/zone-categories', { params: param })
}

const getReplenishStatuses = (param) => {
  return http.get('/replenish-statuses', { params: param })
}

const deleteZone = (data) => {
  return http.post(`/zones/bulk/delete`, data)
}

const deleteZoneItem = (data) => {
  return http.post(`/zone-items/bulk/delete`, data)
}

const replenishZone = (data) => {
  return http.post(`/replenish/zones`, data)
}

const replenishZoneItem = (data) => {
  return http.post(`/replenish/zone-items`, data)
}

const ZoneService = {
  getAll,
  get,
  createZone,
  updateZone,
  deleteZone,
  getZoneCategories,
  getReplenishStatuses,
  deleteZoneItem,
  replenishZone,
  replenishZoneItem,
}

export default ZoneService
