import { PO_CONDITIONS_DATA, PO_STATUSES_DATA } from '../actions/actionType'
const initialState = {
  conditions: [],
  statuses: [],
}
export default function PurchaseOrderReducer(state = initialState, action) {
  switch (action.type) {
    case PO_CONDITIONS_DATA:
      return { ...state, conditions: action.payload }
    case PO_STATUSES_DATA:
      return { ...state, statuses: action.payload }
    default:
      return state
  }
}
