import { USER_NOT_SAVED_DATA } from '../actions/actionType'
const initialState = {
  is_not_saved: false,
}
export default function SavedDataReducer(state = initialState, action) {
  switch (action.type) {
    case USER_NOT_SAVED_DATA:
      return { ...state, is_not_saved: action.payload }
    default:
      return state
  }
}
