import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/billing-categories', { params: param })
}

const get = (id) => {
  return http.get(`/billing-categories/${id}`)
}

const create = (data) => {
  return http.post('/billing-categories', data)
}

const update = (id, data) => {
  return http.put(`/billing-categories/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/billing-categories/${id}`)
}

const removeAll = () => {
  return http.delete(`/billing-categories`)
}

const findByTitle = (title) => {
  return http.get(`/billing-categories?title=${title}`)
}

const BillingCategoryDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
}

export default BillingCategoryDataService
