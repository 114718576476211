import { CHANGE_LANGUAGE_DATA, LANGUAGE_DATA, LOGOUT_BEGIN } from '../actions/actionType'
const initialState = {
  current: {},
  languages: [],
}
export default function LanguageReducer(state = initialState, action) {
  switch (action.type) {
    case LANGUAGE_DATA:
      return { ...state, languages: action.payload }
    case CHANGE_LANGUAGE_DATA:
      return { ...state, current: action.payload }
    case LOGOUT_BEGIN:
      return { ...state, ...initialState }
    default:
      return state
  }
}
