import { APPOINTMENT_STATUS_DATA } from '../actions/actionType'

const initialState = {
  statusList: [],
}
export default function AppointmentStatusesReducer(state = initialState, action) {
  switch (action.type) {
    case APPOINTMENT_STATUS_DATA:
      return { ...state, statusList: action.payload }
    default:
      return state
  }
}
