import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/currencies', { params: param })
}

const CurrenciesDataService = {
  getAll,
}
export default CurrenciesDataService
