import { PROVINCES_DATA } from '../actions/actionType'

const initialState = {
  provinces: [],
}
export default function ProvincesReducer(state = initialState, action) {
  switch (action.type) {
    case PROVINCES_DATA:
      return { ...state, provinces: action.payload }
    default:
      return state
  }
}
