import { TRANSACTION_TYPES_DATA } from '../actions/actionType'
const initialState = {
  transactionTypes: [],
}
export default function TransactionTypesReducer(state = initialState, action) {
  switch (action.type) {
    case TRANSACTION_TYPES_DATA:
      return { ...state, transactionTypes: action.payload }
    default:
      return state
  }
}
