import http from '../utils/http-common'

const getAll = () => {
  return http.get('/modification-types')
}

const ModificationTypeDataService = {
  getAll,
}
export default ModificationTypeDataService
