import { JOBS_STATUS_DATA, JOBS_TYPE_DATA } from '../actions/actionType'

const initialState = {
  jobsType: [],
  jobsStatus: [],
}

export default function JobsReducer(state = initialState, action) {
  switch (action.type) {
    case JOBS_TYPE_DATA:
      return { ...state, jobsType: action.payload }
    case JOBS_STATUS_DATA:
      return { ...state, jobsStatus: action.payload }
    default:
      return state
  }
}
