import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/carriers', { params: param })
}

const get = (id) => {
  return http.get(`/carriers/${id}`)
}

const create = (data) => {
  return http.post('/carriers', data)
}

const update = (id, data) => {
  return http.put(`/carriers/${id}`, data)
}

const remove = (id) => {
  return http.delete(`/carriers/${id}`)
}

const removeAll = () => {
  return http.delete(`/carriers`)
}

const findByTitle = (title) => {
  return http.get(`/carriers?title=${title}`)
}

const getServices = () => {
  return http.get(`/services`)
}

const getTiers = (param) => {
  return http.get('/tiers', { params: param })
}

const CarrierDataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  getServices,
  getTiers,
}

export default CarrierDataService
